import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";




export  class Formblock extends React.Component{
    render(){
        return(
            <Container fluid style={{padding:'0'}}>
                <Container fluid style={{padding:'0'}} className='d-none d-lg-block'>
             <Row style={{position:'relative'}} className='rowform'>
                {/*<Image src={require('./img/Bmwform.png')} className='img-fluid' style={{position:'absolute', zIndex:'-1'}}/>*/}
                <Col lg={{span: 6, offset: 6}} style={{marginTop:'3vw', marginBottom:'3vw'}}>
                <Row><Col style={{ textAlign:'left', backgroundColor:'white', borderRadius:'20px', marginRight:'20px' }}>
                 <Row style={{marginTop:'1.5vw', marginBottom:'2vw'}}>
                  <Col style={{fontSize:'50px', color:'#0346CB', fontWeight:'600'}} lg={{span: 11, offset: 1}}>Остались вопросы?</Col>
                 </Row>
                 <Row style={{ marginBottom:'2vw'}}>
                  <Col lg={{span: 11, offset: 1}} style={{ color:'black', fontSize:'16px'}}>Оставьте заявку на обратный звонок и получите бесплатную консультацию</Col>
                 </Row>
                 <Row style={{ marginBottom:'1.5vw'}}>
                  <Col lg={{span: 8, offset: 1}}>
                   <Form style={{paddingBottom:'1vw'}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                     
                     <Form.Control type="text" placeholder="Имя" id='name' style={{border:'solid 1px #000000', borderRadius:'15px' }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        
                        <Form.Control type="tel" placeholder="Телефон" style={{border:'solid 1px #000000', borderRadius:'15px' }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Я соглашасюсь с политикой конфиденциальности" style={{color:'black'}}/>
                    </Form.Group>
                    <Button className='hover-outline-btn btnn' type='submit' >Оставить заявку</Button>
                    </Form>
                  </Col>
                 </Row>
                 </Col></Row>
                </Col>
             </Row>
             </Container>
             <Container fluid style={{padding:'0'}} className='d-block d-lg-none'>
             <Row><Col style={{ textAlign:'left', backgroundColor:'white', borderRadius:'20px', }}>
                 <Row style={{marginTop:'1.5vw', marginBottom:'2vw'}}>
                  <Col style={{fontSize:'40px', color:'#0346CB', fontWeight:'600'}} lg={{span: 11, offset: 1}}>Остались вопросы?</Col>
                 </Row>
                 <Row style={{ marginBottom:'2vw'}}>
                  <Col lg={{span: 11, offset: 1}} style={{ color:'black', fontSize:'16px'}}>Оставьте заявку на обратный звонок и получите бесплатную консультацию</Col>
                 </Row>
                 <Row style={{ marginBottom:'1.5vw'}}>
                  <Col lg={{span: 8, offset: 1}}>
                   <Form style={{paddingBottom:'1vw'}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                     
                     <Form.Control type="text" placeholder="Имя" id='name' style={{border:'solid 1px #000000', borderRadius:'15px' }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        
                        <Form.Control type="tel" placeholder="Телефон" style={{border:'solid 1px #000000', borderRadius:'15px' }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Я соглашасюсь с политикой конфиденциальности" style={{color:'black'}}/>
                    </Form.Group>
                    <Button className='hover-outline-btn btnn' type='submit' >Оставить заявку</Button>
                    </Form>
                  </Col>
                 </Row>
                 </Col></Row>
             </Container>
            </Container>
        )
    }
}