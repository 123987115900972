import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Form, Pagination, Carousel } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const CarList = () => {
  const [selectedBrand, setSelectedBrand] = useState('Все марки');
  const [selectedModel, setSelectedModel] = useState('Все модели');
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');
  const [carsData, setCarsData] = useState([]); 
  const [filteredCars, setFilteredCars] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 9;

  const [isZoomed, setIsZoomed] = useState(false);

  const carModels = {
    BMW: ['X5 XDrive40i', 'X5 XDrive40i M Sport', 'X5 40i', 'X7 40i', 'X7', '740i XDrive', 'X7 40i M-Sport', 'X6 XDrive40i', '530e XDrive'],
    'Mercedes-Benz': ['S500', 'C300 4matic', 'GLE 53 AMG', 'GLE350', 'GLS450'],
    RAM: ['1500', '1500 Rebel', '1500 TRX'],
    Audi: ['A5 Prestige', 'Q5 Prestige', 'Q5 Premium+', 'S3 Premium+', 'S6 Prestige', 'Q7 Prestige', 'Q7 Premium+', 'Q8 Premium+', 'Q8 Prestige'],
    Jeep: ['Grand Cherokee 4XE', 'Wrangler Rubicon', 'Wrangler Sport'],
    Porsche: ['911 Carrera', 'Cayenne', 'Cayenne Coupe', 'Cayman', 'Panamera', 'Panamera 4S', 'Macan'],
    'Land Rover': ['Range Rover', 'Range Rover Sport', 'Range Rover Westminster', 'Defender 110'],
    'Alfa Romeo': ['Giulia'],
    Ford: ['Mustang', 'Bronco Badlands Advanced', 'F150 Raptor'],
    GMC: ['Sierra K1500 AT4X'],
    Chevrolet: ['Tahoe RST','Silverado K1500 RST', 'Corvette Stingray'],
    Lexus: ['TX 350', 'NX350 Premium', 'LX600 F Sport'],
    DODGE: ['Tahoe RST', 'RAM 1500 Limited'],
  };

  // Функция для получения всех моделей
  const getAllModels = () => {
    return Object.values(carModels).flat();
  };

  useEffect(() => {
    fetch('./API/index.php/cars/get')
      .then((response) => response.json())
      .then((data) => {
        console.log('Данные с сервера:', data.data);
        setCarsData(data.data); 
        setFilteredCars(data.data); 
      })
      .catch((error) => {
        console.error('Ошибка при получении данных:', error);
      });
  }, []);

  const handleApply = () => {
    const priceMin = priceFrom ? parseInt(priceFrom, 10) : 0;
    const priceMax = priceTo ? parseInt(priceTo, 10) : Infinity;
    const yearMin = yearFrom ? parseInt(yearFrom, 10) : 0;
    const yearMax = yearTo ? parseInt(yearTo, 10) : Infinity;
  
    const filtered = carsData.filter((car) => {
      const carPrice = parseInt(car.PRICE.replace(/\D/g, ''), 10);
      const carYear = parseInt(car.YEAR, 10);
      
      const inPriceRange = carPrice >= priceMin && carPrice <= priceMax;
      const inYearRange = carYear >= yearMin && carYear <= yearMax;
      const matchesBrand = selectedBrand === 'Все марки' || car.MARK === selectedBrand;
      const matchesModel = selectedModel === 'Все модели' || car.MODEL === selectedModel;
  
      return inPriceRange && inYearRange && matchesBrand && matchesModel;
    });
  
    setFilteredCars(filtered);
    setCurrentPage(1);
  };

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
    setSelectedModel('Все модели'); 
  };

  // Тут написана логика пагинации
  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;
  const currentCars = filteredCars.slice(indexOfFirstCar, indexOfLastCar);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Функция для преобразования строки с изображениями в массив
  const parseImages = (imageString) => {
    try {
      const imagesArray = JSON.parse(imageString);
      // Удаляем первое изображение и пустые строки, если они есть
      return imagesArray.filter(img => img !== "");
    } catch (error) {
      console.error('Ошибка при разборе строки изображений:', error);
      return [];
    }
  };

  return (
    <div>
      <div className="responsive-form1">
        <Row style={{ margin: '0' }}>
          <Form
            style={{
              backgroundColor: 'white',
              borderRadius: '1vw',
              marginBottom: '1vw',
              padding: '1vw',
              color: 'black',
              textAlign: 'left'
            }}
          >
            <Row>
              <Col>
                <Form.Group controlId="brandSelect">
                  <Form.Label>Марка</Form.Label>
                  <Form.Select value={selectedBrand} onChange={handleBrandChange}>
                    <option>Все марки</option>
                    {Object.keys(carModels).map((brand, index) => (
                      <option key={index}>{brand}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="priceFrom">
                      <Form.Label>Цена</Form.Label>
                      <Form.Control
                        type="number"
                        value={priceFrom}
                        onChange={(e) => setPriceFrom(e.target.value)}
                        placeholder="От"
                      />
                    </Form.Group>
                  </Col>
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Form.Group controlId="priceTo">
                      <Form.Control
                        type="number"
                        value={priceTo}
                        onChange={(e) => setPriceTo(e.target.value)}
                        placeholder="До"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="yearFrom">
                      <Form.Label>Год</Form.Label>
                      <Form.Control
                        type="number"
                        value={yearFrom}
                        onChange={(e) => setYearFrom(e.target.value)}
                        placeholder="От"
                      />
                    </Form.Group>
                  </Col>
                  <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Form.Group controlId="yearTo">
                      <Form.Control
                        type="number"
                        value={yearTo}
                        onChange={(e) => setYearTo(e.target.value)}
                        placeholder="До"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="modelSelect">
                  <Form.Label>Модели</Form.Label>
                  <Form.Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                    <option>Все модели</option>
                    {selectedBrand === 'Все марки'
                      ? getAllModels().map((model, index) => (
                          <option key={index}>{model}</option>
                        ))
                      : carModels[selectedBrand]?.map((model, index) => (
                          <option key={index}>{model}</option>
                        ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginLeft:'auto' }}>
                <Button
                  onClick={handleApply}
                  className="hover-outline-btn btnn"
                  style={{ width: '50%', height: 'auto', marginLeft:'auto' }}
                >
                  Применить
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>

      <div className="responsive-form">
  <Row style={{ margin: '0' }}>
    <Form className="custom-form">
      <Row>
        <Col xs={12} md={12}>
          <Form.Group controlId="brandSelect">
            <Form.Label>Марка</Form.Label>
            <Form.Select value={selectedBrand} onChange={handleBrandChange}>
              <option>Все марки</option>
              {Object.keys(carModels).map((brand, index) => (
                <option key={index}>{brand}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        <Row>
  <Col xs={12} md={6}>
    <Row style={{ alignItems: 'flex-end' }}>
      <Col xs={6}>
        <Form.Group controlId="priceFrom">
          <Form.Label>Цена</Form.Label>
          <Form.Control
            type="number"
            value={priceFrom}
            onChange={(e) => setPriceFrom(e.target.value)}
            placeholder="От"
          />
        </Form.Group>
      </Col>
      <Col xs={6}>
        <Form.Group controlId="priceTo">
          <Form.Control
            type="number"
            value={priceTo}
            onChange={(e) => setPriceTo(e.target.value)}
            placeholder="До"
          />
        </Form.Group>
      </Col>
    </Row>
  </Col>

  <Col xs={12} md={6}>
    <Row style={{ alignItems: 'flex-end' }}>
      <Col xs={6}>
        <Form.Group controlId="yearFrom">
          <Form.Label>Год</Form.Label>
          <Form.Control
            type="number"
            value={yearFrom}
            onChange={(e) => setYearFrom(e.target.value)}
            placeholder="От"
          />
        </Form.Group>
      </Col>
      <Col xs={6}>
        <Form.Group controlId="yearTo">
          <Form.Control
            type="number"
            value={yearTo}
            onChange={(e) => setYearTo(e.target.value)}
            placeholder="До"
          />
        </Form.Group>
      </Col>
    </Row>
  </Col>
</Row>

        <Col xs={12} md={12}>
          <Form.Group controlId="modelSelect">
            <Form.Label>Модели</Form.Label>
            <Form.Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
              <option>Все модели</option>
              {selectedBrand === 'Все марки'
                ? getAllModels().map((model, index) => (
                    <option key={index}>{model}</option>
                  ))
                : carModels[selectedBrand]?.map((model, index) => (
                    <option key={index}>{model}</option>
                  ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-end" style={{marginTop:'1rem'}}>
        <Col xs="auto">
          <Button onClick={handleApply} className="hover-outline-btn btnn" style={{ width: '100%' }}>
            Применить
          </Button>
        </Col>
      </Row>
    </Form>
  </Row>
</div>

      {/* Отображение карточек автомобилей */}
      <div className="card-container">
        <Row>
          {currentCars.length > 0 ? (
            currentCars.map((car, index) => (
              <Col lg={4} sm={6} style={{ marginTop: '.8rem' }} key={index}>
                <Card style={{ width: '100%' }}>
                  <Card.Body className="carcard">
                    {/* Галерея изображений */}
                    <Carousel interval={isZoomed ? null : 1800}  controls={false}   indicators={true} >
                      {parseImages(car.IMAGE).map((image, imgIndex) => (
                      <Carousel.Item key={imgIndex}>
                        <Zoom onZoomChange={(zoomState) => setIsZoomed(zoomState)}>
                         <img className="d-block w-100 carousel-img" src={`./${image}`} alt={`Фото ${imgIndex + 1}`}  style={{ cursor: 'pointer' }} loading="lazy"/>
                       </Zoom>
                     </Carousel.Item>
                       ))}
                    </Carousel>
                    
                    <Card.Title className="NameCar">{car.MARK} <span className="NameModelCar">{car.MODEL}</span></Card.Title>
                    <Card.Text>
                      <Row className="rowgod">
                        <Col className="god"> Год</Col>
                        <Col className="godname">{car.YEAR}</Col>
                      </Row>
                      <Row className="rowcena">
                        <Col className="cena">{car.PRICE} руб.</Col>
                      </Row>
                    </Card.Text>
                    <Button className="hover-outline-btn btnn">Забронировать авто</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p>Нет доступных автомобилей по выбранным параметрам.</p>
          )}
        </Row>
      </div>

      {/* Pagination Component */}
      {filteredCars.length > carsPerPage && (
        <Pagination className="justify-content-center" style={{ marginTop: '1rem' }}>
          {Array.from({ length: Math.ceil(filteredCars.length / carsPerPage) }, (_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}
    </div>
  );
};

export default CarList;