import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import  CarList from "../filter";
import { Podpiska } from '../podpiska';
import  YandexMap  from '../yandexmap';

export  class Catalog extends React.Component{
    render(){
        return(
           <Container fluid>
                          <Helmet>
                <title>Каталог автомобилей из Америки</title>
                <meta name="description" content="Каталог автомобилей из США с возможностью подбора и покупки под заказ. Предлагаем автомобили с аукционов США, проверенные и с полным пакетом документов. Организуем доставку машины на автовозе, поможем с оформлением всех документов и обеспечим страхование груза. Все организационные вопросы берем на себя." />
                <meta name="keywords" content="доставка автомобилей из США в Россию, купить автомобиль из Америки, авто из США под ключ, импорт автомобилей в Россию, доставка авто на автовозе, покупка и доставка авто из США, выгодные автомобили из Америки, авто с аукционов США, быстрая доставка автомобилей, покупка авто в США с доставкой, страхование автомобиля при доставке, оформление документов на авто из США, помощь в подборе автомобиля, доставка автомобилей под заказ, покупка авто в кредит из США, транспортировка автомобилей из Америки, автоперевозки из США в Россию, преимущества авто из Америки, оценка стоимости доставки автомобиля, доставка автомобилей в краткие сроки"/>
              </Helmet>

            <Row className='razdelzagl1'><Col>Выбери идеальное авто для себя</Col></Row>
            <CarList/>
            <Row className='razdelzagl1'><Col></Col></Row>
            <Podpiska/>
            <Row className='razdelzagl1'><Col></Col></Row>
              <YandexMap/>
              <Row className='razdelzagl1'><Col></Col></Row>
           </Container>
        )
    }
}