import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import  YandexMap  from '../yandexmap';

export class Contacts extends React.Component{
    render(){
        return(
            <Container fluid>
                <Row className='razdelzagl1'><Col></Col></Row>    
                <Row className='razdelzagl1'><Col></Col></Row>    
                <Row>
                    <Col lg={5} style={{textAlign:'left', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Row style={{marginBottom:'1rem', fontWeight:'500'}}><Col lg={{ span: 4, offset: 4 }}>Контакты</Col></Row>
                    <Row><Col lg={{ span: 4, offset: 4 }} >+7(906) 906-71-67</Col></Row>
                    <Row><Col lg={{ span: 4, offset: 4 }}>г.Новосибирск, Волочаевская 64/2</Col></Row>
                    </Col>
                    <Col lg={5}>
                    <YandexMap/>
                    </Col>
                </Row>
            </Container>
          

        )
    }
}