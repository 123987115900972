import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { Catalog } from './pages/catalog';
import { Blog } from './pages/blog';
import { Contacts } from './pages/contacts';
import { Header } from './header';
import { Footer } from './footer';
import ScrollToHashElement from './scrollhash';


function App() {
  return (
    <div className="App">
      <Header/>
      <BrowserRouter>
      <ScrollToHashElement /> {/* Добавляем компонент для обработки якорных ссылок */}
        <Routes>
          <Route path="/contacts" element={<Contacts/>}/>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/catalog" element={<Catalog/>}/>
          <Route path="/" element={<Home/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;
