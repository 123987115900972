import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

const YandexMap = () => {
  useEffect(() => {
    // Проверка, был ли уже загружен скрипт Яндекс.Карты
    if (!document.getElementById('yandex-map-script')) {
      const script = document.createElement('script');
      script.id = 'yandex-map-script';  // Задаём ID для скрипта
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.async = true;
      script.src = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A983bd1e91dae2f4ca7e1ea7888241ec8537bdb5e605f610fef9eb9d3925f7a7b&amp;width=706&amp;height=400&amp;lang=ru_RU&amp;scroll=true';

      document.getElementById('yandex-map').appendChild(script);
    }
  }, []); // Эффект должен выполняться один раз при монтировании

  return (
    <Container fluid>
    <div id="yandex-map" className='yamap'>
      {/* Карта будет загружена сюда */}
    </div>
    </Container>
  );
};

export default YandexMap;