import React from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "./img/logo192.svg" ;
import telegramfirst from "./img/telegramfirst.svg"

export  class Header extends React.Component{
    render(){
        return(
            
     
    <Navbar expand="lg" className="bg-body-tertiary headheader">
      <Container fluid>
        <Navbar.Brand href="/"><Image className="logo img-fluid" src={logo}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px'}}
            navbarScroll
          >
            <Nav.Link className="custom-link" href="/#aboutcompanyus">О компании</Nav.Link>
            <Nav.Link className="custom-link" href="/catalog">Каталог</Nav.Link>
            <Nav.Link className="custom-link" href="/#process">Процесс покупки</Nav.Link>
            <Nav.Link className="custom-link" href="/blog">Блог</Nav.Link>
            <Nav.Link className="custom-link" href="/contacts">Контакты</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
         <Button className="d-none d-lg-block" style={{backgroundColor:'#ffffff00', borderColor:'#ffffff00'}} href="https://t.me/avtoimportnsk"><Image src={telegramfirst}/></Button>
         <Button style={{paddingLeft:'3vw', paddingRight:'3vw', width:'fit-content'}} className='d-none d-lg-block hover-outline-btn btnn'>Оставить заявку</Button>
      </Container>
    </Navbar>
                
            
        )
    }
} 