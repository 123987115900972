import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import WA from "./img/widwa.svg" ;
import TE from "./img/widte.svg" ;
import PH from "./img/widph.svg" ;

export class Vidgets extends React.Component{
    render(){
        return(
            
            <Container fluid>
                <Row>
                    <Col>
                <Row>
                    <Col><Button className="vidgetswa widbut" href="https://wa.me/79069067167"><Image className="widwa img-fluid image-animation" src={WA}/></Button></Col>
                </Row>
                <Row>
                    <Col><Button className="vidgetste widbut" href="https://t.me/avtoimportnsk"><Image className="widte img-fluid image-animation" src={TE}/></Button></Col>
                </Row>
                <Row>
                    <Col><Button className="vidgetsph widbut" href="tel:+79069067167"><Image className="widph img-fluid image-animation" src={PH}/></Button></Col>
                </Row>
                    </Col>
                </Row>
            </Container>
            
        )
    }
}